@media screen and (max-width: 1400px) {

  body,
  html {
    overflow-x: hidden;
    width: 100%;
    height: 100%;
  }
}

.HomeTeam {
  /* margin-top: 5rem; */
  padding-top: 5rem !important;
  background: #F3F8FF;
padding-bottom: 5rem !important;
}

@media screen and (max-width: 576px) {
  .HomeTeam {
    margin-top: 3rem;

  }
}

@media screen and (min-width: 577px) and (max-width: 768px) {
  .HomeTeam {
    margin-top: 3rem;
  }
}

.HomeTeam h1 {
  font-family: 'Clash Display';
  font-style: normal;
  font-weight: 600;
  font-size: 48px;
  line-height: 110%;
  text-align: center;
  padding-top: 0rem !important;
  color: #060606;
}

@media screen and (max-width: 576px) {
  .HomeTeam h1 {
    font-family: 'Clash Display';
    font-style: normal;
    font-weight: 600;
    font-size: 23px;
    line-height: 120%;
    text-align: center !important;
    letter-spacing: -0.02em;
    color: #060606;
    padding-top: 3rem !important;
  }
}

@media screen and (min-width: 577px) and (max-width: 768px) {
  .HomeTeam h1 {
    font-family: 'Clash Display';
    font-style: normal;
    font-weight: 600;
    font-size: 23px;
    line-height: 120%;
    text-align: center;
    letter-spacing: -0.02em;
    color: #060606;
  }
}
@media screen and (min-width: 769px) and (max-width: 922px) {
  .HomeTeam h1 {
    font-family: 'Clash Display';
    font-style: normal;
    font-weight: 600;
    font-size: 23px;
    line-height: 120%;
    text-align: center;
    letter-spacing: -0.02em;
    color: #060606;
  }
}
@media screen and (min-width: 993px) and (max-width: 1200px) {
  .HomeTeam h1 {
    font-family: 'Clash Display';
    font-style: normal;
    font-weight: 600;
    font-size: 23px;
    line-height: 120%;
    text-align: center;
    letter-spacing: -0.02em;
    color: #060606;
  }
}

.ParentTeam {
  /* display: grid;
  grid-template-columns: 50% 50%; */
  display: flex;
  flex-wrap: wrap;
  margin-top: 5rem;
  justify-content: center !important;
}

@media screen and (max-width: 576px) {
  .ParentTeam {
    display: flex;
    flex-wrap: wrap !important;
    margin-top: 3rem;
    justify-content:space-around !important;
  }
}

@media screen and (min-width: 577px) and (max-width: 768px) {
  .ParentTeam {
    display: flex;
    flex-wrap: wrap;
    margin-top: 2rem;
  }
}

@media screen and (min-width: 769px) and (max-width: 922px) {
  .ParentTeam {
    display: flex;
    flex-wrap: wrap;
    margin-top: 2rem;
  }
}

@media screen and (min-width: 923px) and (max-width: 1200px) {
  .ParentTeam {
    display: flex;
    flex-wrap: wrap;
    margin-top: 2rem;
  }
}

.ChildTeam {
  margin-right: 5rem;
  border-radius: 8px;
  margin-bottom: 2rem;
}

@media screen and (max-width: 576px) {
  .ChildTeam {
    margin-right: 0rem;
    border-radius: 12px;
    margin-bottom: 2rem;
  }
}

@media screen and (min-width: 577px) and (max-width: 768px) {
  .ChildTeam {
    margin-right: 2rem;
    border-radius: 12px;
    margin-bottom: 2rem;
  }
}

@media screen and (min-width: 769px) and (max-width: 922px) {
  .ChildTeam {
    margin-right: 1rem;
    border-radius: 12px;
    margin-bottom: 2rem;
  }
}

@media screen and (min-width: 923px) and (max-width: 1200px) {
  .ChildTeam {
    margin-right: 1rem;
    border-radius: 12px;
    margin-bottom: 2rem;
  }
}

.ChildTeam2 {
  border-radius: 8px;
  margin-bottom: 2rem;
  margin-right: 5rem;
}

@media screen and (max-width: 576px) {
  .ChildTeam2 {
    border-radius: 12px;
    margin-bottom: 2rem;
    margin-right: 0rem !important;
  }
}
@media screen and (min-width: 577px) and (max-width: 768px) {
  .ChildTeam2 {
    margin-right: 2rem;
    border-radius: 12px;
    margin-bottom: 2rem;
  }
}

@media screen and (min-width: 769px) and (max-width: 922px) {
  .ChildTeam2 {
    margin-right: 1rem;
    border-radius: 12px;
    margin-bottom: 2rem;
  }
}

@media screen and (min-width: 923px) and (max-width: 1200px) {
  .ChildTeam2 {
    margin-right: 1rem;
    border-radius: 12px;
    margin-bottom: 2rem;
  }
}

.ChildTeam img {
  width: 150px;
  height: 150px;
  border-radius: 8px !important;
}

@media screen and (max-width: 576px) {
  .ChildTeam img {
    width: 100px;
    height: 100px;
    border-radius: 0px;
  }
}

@media screen and (min-width: 577px) and (max-width: 768px) {
  .ChildTeam img {
    width: 150px;
    height: 150px;
    border-radius: 0px;
  }
}

.ChildTeam2 img {
  width: 150px;
  height: 150px;
  border-radius: 8px !important;
}

@media screen and (max-width: 576px) {
  .ChildTeam2 img {
    width: 100px;
    height: 100px;
    border-radius: 0px;
  }
}

@media screen and (min-width: 577px) and (max-width: 768px) {
  .ChildTeam2 img {
    width: 150px;
    height: 150px;
    border-radius: 0px;
  }
}

.ChildTeam p {
  font-family: "Satoshi";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 20%;
  text-align: left;
  color: #060606;
  margin-left: 0rem;
  margin-top: 1.5rem;
}

@media screen and (max-width: 576px) {
  .ChildTeam p {
    font-family: "Satoshi";
    font-style: normal;
    font-weight: 500;
    font-size: 13px;
    line-height: 0%;
    text-align: left;
    color: #060606;
    margin-left: 0rem;
    margin-top: 1.5rem;
  }
}

.ChildTeam h6 {
  font-family: "Satoshi";
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  line-height: 0%;
  color: #797979;
  margin-left: 0rem;
}

@media screen and (max-width: 576px) {
  .ChildTeam h6 {
    font-family: "Satoshi";
    font-weight: 500;
    font-size: 11px;
    line-height: 0%;
    color: #797979;
    text-align: left;
    color: #060606;
    margin-left: 0rem;
  }
}

.ChildTeam2 p {
  font-family: "Satoshi";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 0%;
  text-align: left;
  color: #060606;
  margin-left: 0rem;
  margin-top: 1.5rem;
}

@media screen and (max-width: 576px) {
  .ChildTeam2 p {
    font-family: "Satoshi";
    font-style: normal;
    font-weight: 500;
    font-size: 13px;
    line-height: 0%;
    text-align: left;
    color: #060606;
    margin-left: 0rem;
    margin-top: 1.5rem;
  }
}

.ChildTeam2 h6 {
  font-family: "Satoshi";
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  line-height: 0%;
  color: #797979;
  margin-left: 0rem;
}

@media screen and (max-width: 576px) {
  .ChildTeam2 h6 {
    font-family: "Satoshi";
    font-weight: 500;
    font-size: 11px;
    line-height: 0%;
    color: #797979;
    text-align: left;
    color: #060606;
    margin-left: 0rem;
  }
}