@media screen and (max-width: 1400px) {
  body,
  html {
    overflow-x: hidden;
    width: 100%;
    height: 100%;
  }
}

.Home7PageMain {
  background: linear-gradient(
    228.65deg,
    #02040F 18.37%,
    rgba(238, 246, 240, 0.46) 63.94%
  );
  transform: matrix(1, 0, 0, 1, 0, 0);
  padding-top: 5rem !important;
  padding-bottom: 5rem !important;
}

@media screen and (max-width: 576px) {
  .Home7PageMain {
    background: linear-gradient(
      228.65deg,
      #02040F 18.37%,
      rgba(238, 246, 240, 0.46) 63.94%
    );
    transform: matrix(1, 0, 0, 1, 0, 0);
    padding-top: 2rem !important;
    padding-bottom: 2rem !important;
  }
}

@media screen and (min-width: 577px) and (max-width: 768px) {
  .Home7PageMain {
    background: linear-gradient(
      228.65deg,
      #02040F 18.37%,
      rgba(238, 246, 240, 0.46) 63.94%
    );
    transform: matrix(1, 0, 0, 1, 0, 0);
    padding-top: 2rem !important;
    padding-bottom: 2rem !important;
  }
}

@media screen and (min-width: 769px) and (max-width: 992px) {
  .Home7PageMain {
    background: linear-gradient(
      228.65deg,
      #02040F 18.37%,
      rgba(238, 246, 240, 0.46) 63.94%
    );
    transform: matrix(1, 0, 0, 1, 0, 0);
    padding-top: 2rem !important;
    padding-bottom: 2rem !important;
  }
}

.HomePage7Body {
  padding-top: 2rem !important;
  margin-top: 0rem !important;
}
@media screen and (max-width: 576px) {
  .HomePage7Body {
    padding-top: 0rem !important;
    margin-top: 0rem !important;
  }
}
@media screen and (min-width: 577px) and (max-width: 768px) {
  .HomePage7Body {
    padding-top: 0rem !important;
    margin-top: 0rem !important;
  }
}


.HomePage7Body h4 {
  font-family: "Clash Display";
  font-style: normal;
  font-weight: 600;
  font-size: 48px;
  line-height: 48px;
  color: #081f14;
  text-align: center !important;
}

@media screen and (max-width: 576px) {
  .HomePage7Body h4 {
    font-family: "Clash Display";
    font-style: normal;
    font-weight: 600;
    font-size: 23px;
    line-height: 120%;
    color: #fafafa;
  }
}

@media screen and (min-width: 577px) and (max-width: 768px) {
  .HomePage7Body h4 {
    font-family: "Clash Display";
    font-style: normal;
    font-weight: 600;
    font-size: 23px;
    line-height: 120%;
    color: #081f14;
  }
}

.homePageChild {
  margin-top: 4rem !important;
  width: 60% !important;
  margin-left: 20% !important;

}
@media screen and (max-width: 576px) {
  .homePageChild {
    margin-top: 2rem !important;
  }
}
@media screen and (min-width: 577px) and (max-width: 768px) {
  .homePageChild {
    margin-top: 2rem !important;
  }
}

.HomePage7Images img {
  height: 60vh !important;

}

@media screen and (max-width: 576px) {
  .HomePage7Images img {
    height: 40vh !important;
  }
}

@media screen and (min-width: 577px) and (max-width: 768px) {
  .HomePage7Images img {
    height: 40vh !important;
  }
}
