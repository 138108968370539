@media screen and (max-width: 1400px) {

  body,
  html {
    overflow-x: hidden;
    width: 100%;
    height: 100%;
  }
}

.HomeServicesGeneralContainer {
  background: linear-gradient(228.65deg, #F3F8FF 18.37%, rgba(243, 248, 255, 0.46) 63.94%);
  transform: matrix(1, 0, 0, 1, 0, 0);
}

@media screen and (max-width: 576px) {
  .HomeServicesGeneralContainer {
    padding-top: 3rem !important;
  }
}

@media screen and (min-width: 577px) and (max-width: 768px) {
  .HomeServicesGeneralContainer {
    padding-top: 7rem !important;
  }
}

@media screen and (min-width: 769px) and (max-width: 992px) {
  .HomeServicesGeneralContainer {
    padding-top: 3rem !important;
  }
}

@media screen and (min-width: 993px) and (max-width: 1200px) {
  .HomeServicesGeneralContainer {
    padding-top: 3rem !important;
  }
}

.HomeServicesMainParent {
  display: flex;
  /* min-height: 100vh; */
  padding-top: 5rem;
  padding-bottom: 9rem;
}

@media screen and (max-width: 576px) {
  .HomeServicesMainParent {
    display: flex;
    flex-direction: column;
    padding-top: 0rem !important;
  }
}

@media screen and (min-width: 577px) and (max-width: 768px) {
  .HomeServicesMainParent {
    display: flex;
    flex-direction: column;
    padding-top: 0rem !important;
    /* min-height: 100vh; */
  }
}

@media screen and (min-width: 769px) and (max-width: 922px) {
  .HomeServicesMainParent {
    display: flex;
    /* min-height: 100vh; */
    padding-top: 5rem;
    padding-bottom: 5rem;
  }
}

@media screen and (min-width: 923px) and (max-width: 1200px) {
  .HomeServicesMainParent {
    display: flex;
    padding-top: 0rem;
    padding-bottom: 5rem;
  }
}

.HomeServicesParent {
  width: 45%;
  margin-top: 5rem;
}

@media screen and (max-width: 576px) {
  .HomeServicesParent {
    text-align: center;
    justify-content: center;
    align-items: center;
    width: 100%;
    margin-top: 5rem;
  }
}

@media screen and (min-width: 577px) and (max-width: 768px) {
  .HomeServicesParent {
    text-align: center;
    justify-content: center;
    align-items: center;
    width: 100%;
    margin-top: 2rem;
  }
}
@media screen and (min-width: 769px) and (max-width: 922px) {
  .HomeServicesParent {
    text-align: left !important;
    justify-content: left !important;
    align-items: left !important;
    width: 50%;
    margin-top: 3rem;
  }
}
@media screen and (min-width: 923px) and (max-width: 1200px) {
  .HomeServicesParent {
    text-align: left !important;
    justify-content: left !important;
    align-items: left !important;
    width: 50%;
    margin-top: 5rem;
  }
}

.HomeServicesParent h2 {
  font-family: 'Clash Display';
  font-style: normal;
  font-weight: 600;
  font-size: 48px !important;
  line-height: 110%;
  color: #02040F;
}

@media screen and (max-width: 576px) {
  .HomeServicesParent h2 {
    font-family: "Clash Display";
    font-style: normal;
    font-weight: 600;
    font-size: 23px !important;
    line-height: 120%;
    text-align: center;
    color: #081f14;
    margin-top: 3rem;
  }
}

@media screen and (min-width: 577px) and (max-width: 768px) {
  .HomeServicesParent h2 {
    font-family: "Clash Display";
    font-style: normal;
    font-weight: 600;
    font-size: 33px !important;
    /* line-height: 40px; */
    text-align: center;
    color: #081f14;
    padding-top: 3rem;
  }
}

@media screen and (min-width: 769px) and (max-width: 922px) {
  .HomeServicesParent h2 {
    font-family: "Clash Display";
    font-style: normal;
    font-weight: 600;
    font-size: 23px !important;
    line-height: 25px;
    /* text-align: center; */
    color: #081f14;
    text-align: left !important;
  }
}

@media screen and (min-width: 923px) and (max-width: 1200px) {
  .HomeServicesParent h2 {
    font-family: "Clash Display";
    font-style: normal;
    font-weight: 600;
    font-size: 32px !important;
    line-height: 35px;
    color: #081f14;
    text-align: left !important;
  }
}


.HomeServicesParent p {
  font-family: "Satoshi";
  font-style: normal;
  font-weight: 400;
  font-size: 16px !important;
  line-height: 22px;
  color: #41534a;
  padding-top: 1rem;
  padding-bottom: 3rem !important;
  width: 95% !important;
}

@media screen and (max-width: 576px) {
  .HomeServicesParent p {
    font-family: "Satoshi";
    font-style: normal;
    font-weight: 500;
    font-size: 13px !important;
    line-height: 140%;
    /* or 18px */

    text-align: center;
    color: #41534a;
    padding-top: 0.5rem;
  }
}

@media screen and (min-width: 577px) and (max-width: 768px) {
  .HomeServicesParent p {
    font-family: "Satoshi";
    font-style: normal;
    font-weight: 400;
    font-size: 14px !important;
    line-height: 22px;
    text-align: center;
    color: #41534a;
    padding-top: 0.5rem;
  }
}
@media screen and (min-width: 769px) and (max-width: 922px) {
  .HomeServicesParent p {
    font-family: "Satoshi";
    font-style: normal;
    font-weight: 400;
    font-size: 12px !important;
    line-height: 22px;
    text-align: center;
    color: #41534a;
    padding-top: 0.5rem;
    text-align: left !important;
  }
}

@media screen and (min-width: 923px) and (max-width: 1200px) {
  .HomeServicesParent p {
    font-family: "Satoshi";
    font-style: normal;
    font-weight: 500;
    font-size: 14px !important;
    line-height: 22px;
    color: #41534a;
    padding-top: 1rem;
   text-align: left;
  }
}

.HomeServices1button {
  margin-top: 3rem;
}



button {
  background: #1551B1;
  border-radius: 4px;
  border: #1551B1;
  padding: 0.5rem 1rem;

  color: #fafbfa;

  padding: 1rem 1rem;

  font-family: "Clash Display";
  font-style: normal;
  font-weight: 500;
  font-size: 16px !important;
  line-height: 16px;
  text-align: center;
}

@media screen and (max-width: 576px) {
  .HomeServices1button {
    width: 100%;
    margin-top: 2rem;
  }
}

@media screen and (min-width: 577px) and (max-width: 768px) {
  .HomeServices1button {
    width: 100% !important;
    margin-top: 2rem;
  }
}

.HomeServices1ImgMobile {
  display: none;
}

@media screen and (max-width: 576px) {
  .HomeServices1ImgMobile {
    display: unset;
    /* width: 50%;
    text-align: center !important; */
  }
}

@media screen and (min-width: 577px) and (max-width: 768px) {
  .HomeServices1ImgMobile {
    display: unset;
  }
}

@media screen and (max-width: 576px) {
  .HomeServices1ImgMobile img {
    width: 100% !important;
    height: 250px;
    border-radius: 8px;
  }
}

@media screen and (min-width: 577px) and (max-width: 768px) {
  .HomeServices1ImgMobile img {
    width: 100% !important;
    height: 350px;
    border-radius: 12px;
  }
}

.HomeServices1Img {
  width: 50%;
  margin-top: 5rem;
}

@media screen and (min-width: 769px) and (max-width: 922px) {
  .HomeServices1Img {
    width: 45%;
    margin-top: 3rem;
  }
}
@media screen and (min-width: 923px) and (max-width: 1200px) {
  .HomeServices1Img {
    width: 50%;
    margin-top: 5rem;
  }
}

.HomeServices1Img img {
  width: 600px;
  height: 400px;
  border-radius: 24px !important;
}

@media screen and (min-width: 923px) and (max-width: 1200px) {
  .HomeServices1Img img {
    width: 420px;
    height: 300px;
  }
}
@media screen and (min-width: 769px) and (max-width: 922px) {
  .HomeServices1Img img {
    width: 320px;
    height: 300px;
  }
}

@media screen and (max-width: 576px) {
  .HomeServices1Img {
    display: none;
  }
}

@media screen and (min-width: 577px) and (max-width: 768px) {
  .HomeServices1Img {
    display: none;
  }
}

@media screen and (min-width: 769px) and (max-width: 922px) {
  .HomeServices1Img {
    width: 45% !important;
  }
}

.HomeServices1Img img {
  margin-left: 10% !important;
  /* height: 350px; */
}





/* homeServices4 */

.Services4GeneralContainer {
  background: rgba(255, 255, 255, 1);
  padding-top: 4rem;
  margin-bottom: 5rem;
}

@media screen and (max-width: 576px) {
  .Services4GeneralContainer {
    background: rgba(255, 255, 255, 1);
    padding-top: 0rem;
    margin-top: 0rem;
    padding-bottom: 0rem;
  }
}

@media screen and (min-width: 577px) and (max-width: 768px) {
  .Services4GeneralContainer {
    background: rgba(255, 255, 255, 1);
    padding-top: 2rem;
    margin-top: 0rem;
    padding-bottom: 0rem;
  }
}

@media screen and (min-width: 769px) and (max-width: 992px) {
  .Services4GeneralContainer {}
}

.Services4MainContainer h3 {
  font-family: "Clash Display";
  font-style: normal;
  font-weight: 600;
  font-size: 48px;
  line-height: 57px;
  text-align: center;
  color: #081f14;
  width: 70%;
  margin-left: 10rem;
}

@media screen and (min-width: 993px) and (max-width: 1200px) {
  .Services4MainContainer h3 {
    font-family: "Clash Display";
    font-style: normal;
    font-weight: 600;
    font-size: 38px;
    line-height: 57px;
    text-align: center;
    color: #081f14;
    width: 100% !important;
    margin-left: 0rem !important;
  }
}


@media screen and (min-width: 769px) and (max-width: 922px) {
  .Services4GeneralContainer h3 {
    font-family: "Clash Display";
    font-style: normal;
    font-weight: 600;
    font-size: 33px;
    line-height: 57px;
    text-align: center;
    color: #081f14;
    width: 100% !important;
    margin-left: 0rem !important;
  }
}

.Services4MainContainer h6 {
  font-family: "Satoshi";
  font-style: normal;
  font-weight: 600;
  font-size: 48px;
  line-height: 120%;
  /* identical to box height, or 58px */

  text-align: center;

  /* Neutral/N900 */

  color: #02040F;
  width: 100% !important;
  margin-left: 0rem !important;

}

@media screen and (min-width: 769px) and (max-width: 922px) {
  .Services4GeneralContainer h6 {
    font-family: "Satoshi";
    font-style: normal;
    font-weight: 700;
    font-size: 14px !important;
    line-height: 16px;
    letter-spacing: 0.2em;
    text-transform: uppercase;
    color: #02040F;
    text-align: center;
  }
}

@media screen and (max-width: 576px) {
  .Services4MainContainer h3 {
    width: 100%;
    margin-left: 0rem;
    padding-top: 0.5rem !important;
    font-family: "Clash Display";
    font-style: normal;
    font-weight: 600;
    font-size: 18px !important;
    line-height: 29px;
    text-align: center;
    color: #02040F;
  }
}

@media screen and (max-width: 576px) {
  .Services4MainContainer h6 {
    font-family: "Satoshi";
    font-style: normal;
    font-weight: 700;
    font-size: 10px !important;
    line-height: 11px;
    letter-spacing: 0.2em;
    text-transform: uppercase;
    color: #02040F;
    padding-top: 3rem !important;
  }
}

@media screen and (min-width: 577px) and (max-width: 768px) {
  .Services4MainContainer h3 {
    width: 100%;
    margin-left: 0rem;
    padding-top: 0rem;
    margin-top: 0rem !important;

    font-family: "Clash Display";
    font-style: normal;
    font-weight: 600;
    font-size: 23px;
    line-height: 29px;
    text-align: center;
    color: #081f14;
  }
}

@media screen and (min-width: 577px) and (max-width: 768px) {
  .Services4MainContainer h6 {
    width: 100%;
    margin-left: 0rem;
    padding-top: 2rem;

    font-family: "Clash Display";
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 0px;
    text-align: center;
    color: #02040F;
  }
}

.Services4Container {
  display: flex;
  width: 100%;
  flex-wrap: wrap;
  margin-left: 3rem;
  padding-top: 3rem !important;
}

@media screen and (max-width: 576px) {
  .Services4Container {
    display: flex;
    flex-direction: column;
    margin-left: 0rem;
    margin-top: 0rem !important;
  }
}

@media screen and (min-width: 577px) and (max-width: 768px) {
  .Services4Container {
    display: flex;
    flex-direction: column;
    margin-left: 0rem;
    width: 100%;
    margin-top: 0rem;
  }
}

.Services4Parent {
  width: 30% !important;
  background: #ffffff;

  border: 1px solid #dfe2e0;
  border-radius: 16px;
  margin-right: 1rem;
  margin-top: 1rem;
  align-items: center;
  text-align: center;
  padding: 1rem 1rem;
}
.Services4Parent h6 {
  font-family: 'Clash Display';
  font-style: normal;
  font-weight: 500 !important;
  font-size: 23px !important;
  line-height: 120%;
  /* identical to box height, or 28px */
  
  
  /* Neutral/N900 */
  
  color: #02040F;
}
.Services4Parent img{
 height: 30px;
 width: 30px;
}

@media screen and (max-width: 576px) {
  .Services4Parent {
    width: 100% !important;
    background: #ffffff;
    /* Neutral Variant/N40 */

    border: 1px solid #dfe2e0;
    border-radius: 16px;
  }
}

@media screen and (min-width: 577px) and (max-width: 768px) {
  .Services4Parent {
    width: 100% !important;
    background: #ffffff;
    /* Neutral Variant/N40 */

    border: 1px solid #dfe2e0;
    border-radius: 16px;
  }
}

@media screen and (min-width: 769px) and (max-width: 992px) {
  .Services4Parent h6 {
    font-family: "Clash Display";
    font-style: normal;
    font-weight: 500;
    font-size: 12px !important;
    line-height: 16px !important;
    color: #3C3E46;
    text-align: center;
  }
}

@media screen and (max-width: 576px) {
  .Services4Parent h6 {
    font-family: "Clash Display";
    font-style: normal;
    font-weight: 500;
    font-size: 14px !important;
    line-height: 16px !important;
    color: #3C3E46;
    text-align: center;
  }
}

@media screen and (min-width: 577px) and (max-width: 768px) {
  .Services4Parent h6 {
    font-family: "Clash Display";
    font-style: normal;
    font-weight: 500;
    font-size: 14px !important;
    line-height: 16px !important;
    color: #3C3E46;
    text-align: center;
  }
}

@media screen and (min-width: 769px) and (max-width: 992px) {
  .Services4Parent p {
    font-family: "Satoshi";
    font-style: normal;
    font-weight: 400;
    font-size: 10px !important;
    line-height: 19px !important;
    text-align: center;
    color: #02040F;
  }
}

@media screen and (min-width: 577px) and (max-width: 768px) {
  .Services4Parent p {
    font-family: "Satoshi";
    font-style: normal;
    font-weight: 400;
    font-size: 12px !important;
    line-height: 19px !important;
    text-align: center;
    color: #02040F;
  }
}

.Services4Parent img {
  text-align: center;
  align-items: center;
  justify-content: center;
}

@media screen and (min-width: 577px) and (max-width: 768px) {
  .Services4Parent img {
    text-align: center;
    align-items: center;
    justify-content: center;
    height: 40px !important;
  }
}


.Services4Parent h6 {
  font-family: 'Clash Display';
  font-style: normal;
  font-weight: 500;
  font-size: 23px;
  line-height: 120%;

  
  color: #02040F;
  text-align: center;
margin-top: 1rem !important;
}

@media screen and (min-width: 577px) and (max-width: 768px) {
  .Services4Parent h6 {
    font-family: "Clash Display";
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 29px;
    color: #3C3E46;
    text-align: center;
    margin-top: 1rem;
  }
}

.Services4Parent p {
  font-family: "Satoshi";
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 19px;
  text-align: left;
  color: #02040F;
}

@media screen and (max-width: 576px) {
  .Services4Parent p {
    font-family: "Satoshi";
    font-style: normal;
    font-weight: 400;
    font-size: 10px;
    line-height: 19px;
    text-align: center;
    color: #02040F;
  }
}

@media screen and (min-width: 577px) and (max-width: 768px) {
  .Services4Parent p {
    font-family: "Satoshi";
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    text-align: center;
    color: #02040F;
  }
}