@media screen and (max-width: 1400px) {

  body,
  html {
    overflow-x: hidden;
    width: 100%;
    height: 100%;
  }
}


@media screen and (min-width: 577px) and (max-width: 768px) {
  .LogoTrayParent {
    margin-top: 3rem;
  }
}

.mobilecenter {
  text-align: justify;
}

.LogoTrayParent {
  margin-top: 5rem;
}


@media screen and (max-width: 576px) {
  .LogoTrayParent {
    margin-top: 3rem;
  }
}

.LogoTrayParent h4 {
  font-family: "Clash Display";
  font-style: normal;
  font-weight: 600;
  font-size: 48px;
  line-height: 48px;
  color: #081f14;
  text-align: center !important;
}

@media screen and (max-width: 576px) {
  .LogoTrayParent h4 {
    font-family: "Satoshi";
    font-style: normal;
    color: #081f14;
    font-weight: 600;
    font-size: 20px !important;
    line-height: 25px !important;
    text-align: center;
  }
}

@media screen and (min-width: 577px) and (max-width: 768px) {
  .LogoTrayParent h4 {
    font-family: "Satoshi";
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 16px;
    text-align: center;
    color: #081f14;
  }
}
@media screen and (min-width: 769px) and (max-width: 922px) {
  .LogoTrayParent h4 {
    font-family: "Satoshi";
    font-style: normal;
    font-weight: 700;
    font-size: 32px;
    line-height: 16px;
    text-align: center;
    color: #081f14;
  }
}

.LogoTrayChildren {
  /* justify-content: space-around !important; */
  width: 100% !important;
  margin-top: 5rem;
  justify-content: space-evenly;
  display: flex;
  flex-wrap: wrap;
}

.LogoTrayChildren img {
  margin-right: 3rem;
  margin-bottom: 2rem;
  border-radius: 2px !important;
  /* height: 120px !important;
  width: 120px !important; */
}

@media screen and (min-width: 577px) and (max-width: 768px) {
  .LogoTrayChildren {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
  }
}

@media screen and (min-width: 769px) and (max-width: 922px) {
  .LogoTrayChildren {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }
}

@media screen and (min-width: 769px) and (max-width: 922px) {
  .LogoTrayChildren img {
    height: 120px;
    width: 120px;
  }
}

@media screen and (min-width: 577px) and (max-width: 768px) {
  .LogoTrayChildren img {
    height: 80px !important;
    width: 80px !important;
    /* padding-right: 1rem; */
    padding-bottom: 1rem;
  }
}

@media screen and (min-width: 923px) and (max-width: 1200px) {
  .LogoTrayChildren img {
    height: 180px;
    width: 180px;
    /* padding-bottom: 1rem; */
  }
}

@media screen and (max-width: 576px) {
  .LogoTrayChildren {
    display: none;
  }
}

/* @media screen and (max-width: 768px) {
  .LogoTrayChildren{
  display: flex;
  }
} */

.LogoTrayChildrenMobile {
  display: none;
}

@media screen and (max-width: 576px) {
  .LogoTrayChildrenMobile {
    display: unset;
    display: flex;
    flex-wrap: nowrap !important;
    justify-content: space-around !important;
    width: 100% !important;
    margin-top: 5rem;
  }
}

@media screen and (max-width: 576px) {
  .LogoTrayChildrenMobile img {
    width: 80px !important;
    height: 80px;
  }
}