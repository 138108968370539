@media screen and (max-width: 1400px) {

  body,
  html {
    overflow-x: hidden;
    width: 100%;
    height: 100%;
  }
}

nav{
  background: #F3F8FF;
  backdrop-filter: blur(4px);
}


.logo img{
  height: 40px !important;
  width: 40px !important;
  border-radius: 100px !important;
}

li {
  padding-right: 1.8rem;
}

.Navbutton :hover {
  background: #3D6FBE !important;
}

.Navbutton :active {
  background: #1551B1 !important;
}


.NavText {
  padding-right: 2rem;
  padding-left: 2rem;
  padding-top: 1rem;
  padding-bottom: 1rem;
  background: #1551B1;
  border-radius: 4px;
  border: 4px rgba(8, 32, 21, 0.95);
  font-family: "Clash Display";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 16px;
  letter-spacing: 0.01em;
  color: #fafbfa;
}

ul li a {
  font-family: "Clash Display";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 100%;
  text-align: center;
  color: #41534a;
  text-decoration: none !important;
}

.navbar-nav li :hover{
  color: #1551B1 !important;
  font-weight: bold !important;
}

/* ul .active {
  color: #1551B1 !important;
  font-weight: bold !important;
} */


@media screen and (max-width: 576px) {
  .Navbutton {
    display: none !important;
      }
}

@media screen and (min-width: 577px) and (max-width: 768px) {
  .Navbutton {
display: none !important;
  }
}

@media screen and (min-width: 769px) and (max-width: 922px) {
  .Navbutton {
    display: none !important;
      }
}

@media screen and (min-width: 923px) and (max-width: 1200px) {
  .Navbutton {
    display: none !important;
      }
}