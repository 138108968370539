.Button {
    border-radius: 4px !important;
    border: 4px solid #1551B1 !important;
    font-family: "Clash Display" !important;
    font-style: normal !important;
    font-weight: 400 !important;
    font-size: 19px !important;
    line-height: 100% !important;
    letter-spacing: 0.01em !important;
    color: #fafafa !important;
    padding: 0.7rem !important;
    background: #1551B1;

  }
  
  @media screen and (max-width: 576px) {
    .Button {
   
      width: 100% !important;
      margin-right: 0rem !important;
    }
  }
  
  .Button:hover {

    background: #3D6FBE !important;
    border-radius: 4px;
    border: 4px solid #3D6FBE !important;
  }
  
  .Button:active {
    background: #1551B1 !important;
    border-radius: 4px;
    border: 4px solid #1551B1!important;
  }
  
  .modal-header {
    border-bottom: none !important ;
  }
  
  .modalHeader {
    display: block !important;
  }
  
  @media screen and (max-width: 576px) {
    .modalHeader {
      display: block !important;
    }
  }
  @media screen and (max-width: 576px) {
    .modalbodyshow{
     width: 60%;
    }
  }
  
  .modalTitle {
    font-family: "Clash Display";
    font-style: normal;
    font-weight: 600;
    font-size: 33px;
    line-height: 110%;
    text-align: center;
    color: #081f14;
  }
  
  .modalSubTitle {
    font-family: "Satoshi";
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 140%;
    text-align: center;
    color: #41534a;
  }
  
  .modalBody {
    margin-top: -1rem !important;
  }
  @media screen and (max-width: 576px) {
    .modalBody{
     width: 60%;
    }
  }
  
  .btn-primary {
    /* background: #1551b1 !important; */
  
    border-radius: 4px !important;
  
    font-family: "Clash Display" !important;
    font-style: normal !important;
    font-weight: 500 !important;
    font-size: 19px !important;
    line-height: 100% !important;
    /* identical to box height, or 19px */
  
    letter-spacing: 0.01em !important;
  
    /* Neutral/N10 */
  
    color: #fafafa !important;
  }
  
  @media screen and (max-width: 576px) {
    .btn-primary {
      width: 100% !important;
    }
  }
  @media screen and (min-width: 577px) and (max-width: 768px) {
    .btn-primary {
      width: 100% !important;
    }
  }
  
  .modal-footer > * {
    /* margin: 1rem !important;
      padding: 20px 48px !important; */
  }
  
  /* .modal-footer{
      border-top: 0rem !important;
    } */
  
  .formFooter {
    /* width: 20% !important;
      background: red !important; */
  }
  
  .formFooter .formFooter2 {
    border: 0px !important;
  
    background: #1551B1;
    border-radius: 4px;
  }
  
  .formFooter .formFooter2:hover {
    background:  #3D6FBE !important;
    border-radius: 4px;
    border: 0px !important;
  }
  
  .formFooter .formFooter2:active {
    background: #1551B1 !important;
    border-radius: 4px;
    border: 0px !important;
  }
  
  /* Secnd Modal */
  
  .form {
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    /* width: 100%; */
    width: 400px;
    font-size: 16px;
  }
  
  .input {
    width: 100%;
    height: 35px;
    padding: 7px;
    outline: none;
    border-radius: 5px;
    border: 1px solid rgb(220, 220, 220);
  }
  .input:focus {
    border: 2px solid rgba(0, 206, 158, 1);
  }
  
  .textarea {
    max-width: 100%;
    min-width: 100%;
    width: 100%;
    max-height: 100px;
    min-height: 100px;
    padding: 7px;
    outline: none;
    border-radius: 5px;
    border: 1px solid rgb(220, 220, 220);
  }
  
  @media screen and (max-width: 576px) {
    .textarea {
      margin-top: 0rem;
      cursor: pointer;
      background: none !important;
      border: none;
    
      font-family: "General Sans";
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 100%;
      letter-spacing: 0.01em;
  
      color: #0C0C0C;
      width: 60% !important;
      min-width: 75%;
  
      padding: 0px !important;
      /* outline: none; */
      border-radius: 5px;
      border: 1px solid rgb(220, 220, 220);
    }
  }
  
  
  .textarea:focus {
    border: 2px solid rgba(0, 206, 158, 1);
  }
  
  .label {
    margin-top: 1rem;
  }
  
  .input[type="submit"] {
    margin-top: 0rem;
    cursor: pointer;
    background: none !important;
    border: none;
  
    font-family: "General Sans";
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 100%;
    letter-spacing: 0.01em;
    color: #fafafa;
  }
  
  
  @media screen and (max-width: 576px) {
    .input {
      margin-top: 0rem;
      cursor: pointer;
      background: none !important;
      border: none;
    
      font-family: "General Sans";
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 100%;
      letter-spacing: 0.01em;
      color: #0C0C0C;
      width: 75% !important;
      
      border-radius: 5px;
      border: 1px solid rgb(220, 220, 220);
    }
  }
  