@media screen and (max-width: 1400px) {
  body,
  html {
    overflow-x: hidden;
    width: 100%;
    height: 100%;
  }
}

.HomePage2MainBody {
  background: #F5F5F5;
  padding-bottom: 5rem;
}

@media screen and (max-width: 576px) {
  .HomePage2MainBody {
    background: #F5F5F5;
    padding-bottom: 3rem;
  }
}

@media screen and (min-width: 577px) and (max-width: 768px) {
  .HomePage2MainBody {
    background: #F5F5F5;
  }
}

.HomePage2Body {
  padding-top: 4rem;
}
@media screen and (max-width: 576px) {
  .HomePage2Body {
    padding-top: 4rem;
  }
}

@media screen and (min-width: 577)and (max-width: 768px) {
.HomePage2Body {
  padding-top: 0rem !important;
 
}
}

.HomePage2Body h6 {
  color: #1551B1;
  font-family: "Satoshi";
  font-style: normal;
  font-weight: 700;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0.2em;
  text-transform: uppercase;
  text-align: center;
}

@media screen and (max-width: 576px) {
  .HomePage2Body h6 {
    font-family: "Satoshi";
    font-style: normal;
    font-weight: 700 !important;
    font-size: 10px !important;
    line-height: 16px;
    letter-spacing: 0.2em;
    text-transform: uppercase;
    color: #39764a;
  }
}

@media screen and (min-width: 577px) and (max-width: 768px) {
  .HomePage2Body h6 {
    font-family: 'Clash Display';
    font-style: normal;
    font-weight: 600;
    font-size: 14px !important;
    line-height: 16px;
    text-transform: uppercase;
    color: #39764a;
    padding-top: 0rem;
  }
}

.HomePage2Body h3 {
  font-family: "Clash Display";
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 30px;
  text-align: center;
  color: #02040F;
  width: 50%;
  margin-left: 25% !important;
}

@media screen and (min-width: 923px) and (max-width: 1200px) {
  .HomePage2Body h3 {
    font-family: "Clash Display";
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 30px;
    text-align: center;
    color: #082015;
    width: 100% !important;
    margin-left: 0rem !important;
  }
}

@media screen and (max-width: 576px) {
  .HomePage2Body h3 {
    font-family: "Clash Display";
    font-style: normal;
    font-weight: 600;
    font-size: 18px !important;
    line-height: 25px;
    text-align: center;
    color: #081f14;
    width: 100% !important;
    margin-left: 0rem !important;
  }
}

@media screen and (min-width: 577px) and (max-width: 768px) {
  .HomePage2Body h3 {
    font-family: "Clash Display";
    font-style: normal;
    font-weight: 400;
    font-size: 14px !important;
    line-height: 23px;
    text-align: center;
    color: #081f14;
    width: 100% !important;
    margin-left: 0rem !important;
  }
}

@media screen and (min-width: 769px) and (max-width: 922px) {
  .HomePage2Body h3 {
    font-family: "Clash Display";
    font-style: normal;
    font-weight: 600;
    font-size: 22px !important;
    line-height: 22px !important;
    text-align: center;
    color: #082015;
    width: 100%;
    margin-left: 0rem !important;
  }
}
.HomePage2Body h3 span {
  /* color: #ed5b49; */
}

.HomePage2Cards img {
  padding: 1rem 1rem;
}

@media screen and (min-width: 577px) and (max-width: 768px) {
  .HomePage2Cards img {
   height: 30vh !important;
  }
}
@media screen and (min-width: 577px) and (max-width: 768px) {
  .HomePage2Button img {
   height: 8vh !important;
  }
}
@media screen and (min-width: 923px) and (max-width: 1200px) {
  .carditUp {
   height: 20vh !important;
  }
}

.HomePage2Flex {
  display: flex;
  justify-content: center;
  margin-top: 5rem;
  width: 100% !important;
}

@media screen and (min-width: 769px) and (max-width: 992px) {
  .HomePage2Flex {
    display: flex;
    justify-content: center;
    margin-top: 2rem;
  }
}

@media screen and (max-width: 576px) {
  .HomePage2Flex {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
    margin-top: 3rem;
  }
}

@media screen and (min-width: 577px) and (max-width: 768px) {
  .HomePage2Flex {
    display: flex;
    flex-wrap: wrap;
    justify-content: center !important;
    width: 100% !important;
    margin-top: 3rem !important;
  }
}

.HomePage2Cards {
  width: 32% !important;
  border: 0px solid rgba(0, 0, 0, 0.12) !important;
  margin-right: 0.5rem;
}
@media screen and (min-width: 923px) and (max-width: 1200px) {
  .HomePage2Cards {
    width: 50% !important;
    margin-bottom: 1rem;
  }
}

.card {
  border-radius: 12px;
}
.carditUp {
  border-radius: 24px !important;
  height: 250px !important;
}

@media screen and (max-width: 576px) {
  .carditUp{
    height: 30vh !important;
  }
}

@media screen and (min-width: 769px) and (max-width: 992px) {
  .HomePage2Cards {
    width: 30% !important;
    background: #f8fffc;
    border: 1px solid rgba(0, 0, 0, 0.12);
    border-radius: 12px;
    margin-right: 0.5rem;
  }
}

@media screen and (max-width: 576px) {
  .HomePage2Cards {
    width: 100% !important;
    margin-bottom: 1rem;
  }
}

@media screen and (min-width: 577px) and (max-width: 768px) {
  .HomePage2Cards {
    width: 100% !important;
    margin-bottom: 1rem;
  }
}

.HomePage2Cards h4 {
  font-family: "Clash Display";
  font-style: normal;
  font-weight: 500;
  font-size: 23px;
  line-height: 29px;
  color: #081f14;
}

@media screen and (max-width: 576px) {
  .HomePage2Cards h4 {
    font-family: "Clash Display";
    font-style: normal;
    font-weight: 500;
    font-size: 18px !important;
    line-height: 29px;
    color: #081f14;
  }
}

@media screen and (min-width: 577px) and (max-width: 768px) {
  .HomePage2Cards h4 {
    font-family: "Clash Display";
    font-style: normal;
    font-weight: 500;
    font-size: 23px;
    line-height: 29px;
    color: #081f14;
  }
}
@media screen and (min-width: 769px) and (max-width: 922px) {
  .HomePage2Cards h4 {
    font-family: "Clash Display";
    font-style: normal;
    font-weight: 500 !important;
    font-size: 16px !important;
    line-height: 29px;
    color: #081f14;
  }
}
@media screen and (min-width: 923px) and (max-width: 1200px) {
  .HomePage2Cards h4 {
    font-family: "Clash Display";
    font-style: normal;
    font-weight: 500 !important;
    font-size: 18px !important;
    line-height: 29px;
    color: #081f14;
  }
}



.HomePage2Cards p {
  font-family: "Satoshi";
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 19px;
  color: #41534a;
}

@media screen and (min-width: 577px) and (max-width: 768px) {
  .HomePage2Cards p {
    font-family: "Satoshi";
    font-style: normal;
    font-weight: 400;
    font-size: 13px;
    line-height: 19px;
    color: #41534a;
  }
}
@media screen and (min-width: 769px) and (max-width: 922px) {
  .HomePage2Cards p {
    font-family: "Satoshi";
    font-style: normal;
    font-weight: 400 !important;
    font-size: 12px !important;
    line-height: 18px;
    color: #081f14;
  }
}

.x :hover {
  background: #1551B1;
}

.x :active {
  background: #1551B1;
}

.HomePage2Button{
  background: #1551B1 !important;
  border-radius: 4px;
  color: #fafbfa;
  padding: 0rem 0.5rem;
  font-family: "Clash Display";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  padding-left: 1rem !important;
  padding-right: -2rem !important;
 
}
@media screen and (min-width: 923px) and (max-width: 1200px) {
  .HomePage2Button{
    background: #1551B1 !important;
    border-radius: 4px;
    color: #fafbfa;
    padding: 0rem 0.5rem;
    font-family: "Clash Display";
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
  
   
  }
}
@media screen and (max-width: 576px) {
  button {
    font-family: "Clash Display";
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 16px;
    letter-spacing: 0.01em;
    color: #fafbfa;
  }
}

@media screen and (max-width: 576px) {
  .HomePage2Button {
    width: 100%;
  }
}

@media screen and (min-width: 577px) and (max-width: 768px) {
  .HomePage2Button {
    width: 100%;
  }
}
/* @media screen and (min-width: 923px) and (max-width: 1200px) {
  .HomePage2Button img{
    height: 2vh !important;
    width: 10% !important;
  }
} */

@media screen and (min-width: 577px) and (max-width: 768px) {
  button {
    margin-top: 2rem;
    margin-bottom: 1rem;
  }
}

.minheight {
  min-height: 150px !important;
}
.minheight h4 {
  font-family: 'Clash Display';
  font-style: normal;
  font-weight: 500;
  font-size: 23px;
  line-height: 120%;
  color: #02040F;
}

@media screen and (max-width: 576px) {
  .minheight {
    min-height: 140px !important;
  }
}
@media screen and (min-width: 577px) and (max-width: 768px) {
  .minheight {
    min-height: 100px !important;
  }
}
@media screen and (min-width: 923px) and (max-width: 1200px) {
  .minheight {
    min-height: 200px !important;
  }
}

@media screen and (min-width: 577px) and (max-width: 768px) {
  .minheight h4{
    font-family: 'Clash Display';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 120%;
margin-top: -1rem;
  }
}

@media screen and (min-width: 577px) and (max-width: 768px) {
  .minheight p{
    font-family: "Satoshi";
    font-style: normal;
    font-style: normal;
    font-weight: 400;
    font-size: 11px;
  }
}