@media screen and (max-width: 1400px) {
  body,
  html {
    overflow-x: hidden;
    width: 100%;
    height: 100%;
  }
}

.FooterMobilGrandParent{
  display: none !important;
}

@media screen and (min-width: 577px) and (max-width: 768px) {
  .FooterMobilGrandParent {
    display: none;
  }
}


@media screen and (min-width: 769px)and (max-width: 992px) {
  .FooterMobilGrandParent {
    display: none;
  }
}



@media screen and (min-width: 993px)and (max-width: 1200px) {
  .FooterMobilGrandParent {
    display: none;
  }
}


@media screen and (min-width: 1201px)and (max-width: 1400px) {
  .FooterMobilGrandParent {
    display: none;
  }
}



@media screen and (max-width: 576px) {
  .FooterMobilGrandParent {
    display: unset !important;
    background: #02040F;
  }
}
@media screen and (max-width: 576px) {
  .FooterMobilImg img{
 height: 40px;
 width: 40px;
 border-radius: 8px !important;
  }
}

@media screen and (max-width: 576px) {
    .FooterMobilSubGrandParent{
      background: #02040F;
    }
  }

@media screen and (max-width: 576px) {
  .FooterMobilParent {
    background: #02040F;
  }
}


@media screen and (max-width: 576px) {
  h2 button {
    font-family: "Clash Display";
    font-style: normal;
    font-weight: 500;
    font-size: 19px;
    line-height: 25px;
    color: #c1c7c4 !important;
  }
}


@media screen and (max-width: 576px) {
  ul li a {
    font-family: "Clash Display";
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 22px;
    color: #c1c7c4 !important;
  }
}


@media screen and (max-width: 576px) {
  ul li {
    list-style-type: none;
    padding-top: 1rem;
  }
}
/* @media screen and (min-width: 577px) and (max-width: 776px) {
  ul li {
    list-style-type: none;
    padding-top: 1rem;
  }
} */
@media screen and (max-width: 576px) {
  .FooterMobilImg {
    text-align: center !important;
  }
}
/* @media screen and (min-width: 577px) and (max-width: 776px) {
   .FooterMobilImg {
    text-align: center !important;
  }
} */
@media screen and (max-width: 576px) {
  .FooterMobilImg img {
    margin-top: 3rem;
  }
}

/* @media screen and (min-width: 577px) and (max-width: 776px) {
  .FooterMobilImg img {
    margin-top: 3rem;
  }
} */

@media screen and (max-width: 576px) {
  .FooterMobilImg p {
    font-family: "Satoshi";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    text-align: center;
    color: #c1c7c4;
    padding-top: 1rem;
    padding-left: 2rem;
    padding-right: 2rem;
  }
}


/* @media screen and (min-width: 577px) and (max-width: 776px) {
  .FooterMobilImg p {
    font-family: "Satoshi";
    font-style: normal;
    font-weight: 400;
    font-size: 18px !important;
    line-height: 20px;
    text-align: center;
    color: #c1c7c4;
    padding-top: 1rem;
    padding-left: 2rem;
    padding-right: 2rem;
  }
} */


@media screen and (max-width: 576px) {
    .FooterGrandPaa {
        display: flex;
        flex-wrap: wrap;
        margin-top: 3rem;
        justify-content: center;
        
      }
  }

  /* @media screen and (min-width: 577px) and (max-width: 776px) {
    .FooterGrandPaa {
      display: flex;
      flex-wrap: wrap;
      margin-top: 3rem;
      justify-content: center;
      
    }
  } */
  
  @media screen and (max-width: 576px) {
    .socilaMediaHandles22 {
        padding-left: 1rem;
        padding-top: 1rem;
      }
  }
  
  /* @media screen and (min-width: 577px) and (max-width: 776px) {
    .socilaMediaHandles22 {
      padding-left: 0.5rem;
      padding-top: 1rem;
    }
  } */

  @media screen and (max-width: 576px) {
    .socilaMediaHandles22 img {
        width: 30px;
        height: 30px;
      }
  }
/* 
  @media screen and (min-width: 577px) and (max-width: 776px) {
    .socilaMediaHandles22 img {
      width: 40px;
      height: 40px;
    }
  } */

  @media screen and (max-width: 576px) {
    .FooterCopyrightt {
        margin-top: 1.5rem;
        text-align: center;
        padding-bottom: 1rem;
      }
  }

  
  /* @media screen and (min-width: 577px) and (max-width: 776px) {
    .FooterCopyrightt {
      margin-top: 1.5rem;
      text-align: center;
      padding-bottom: 1rem;
    }
  } */
  
  @media screen and (max-width: 576px) {
    .FooterCopyrightt p {
        font-family: "Clash Display";
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 22px;
        color: rgba(255, 255, 255, 0.5);
      }
  }
  /* @media screen and (max-width: 576px) {
    .accordion-button::after {
      transition: none !important;
      border-color: red !important;
    
      }
  } */
 
/*   
  @media screen and (min-width: 577px) and (max-width: 776px) {
    .FooterCopyrightt p {
      font-family: "Clash Display";
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 22px;
      color: rgba(255, 255, 255, 0.5);
    }
  } */
 
  


  