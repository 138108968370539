@media screen and (max-width: 1400px) {

  body,
  html {
    overflow-x: hidden;
    width: 100%;
    height: 100%;
  }
}

.DataStorageMainBody {
  background: linear-gradient(228.65deg,
      #F5F5F5 18.37%);
  transform: matrix(1, 0, 0, 1, 0, 0);
}

@media screen and (max-width: 576px) {
  .DataStorageMainBody {
    /* height: 100vh; */
    background: #ffffff;
  }
}

@media screen and (min-width: 577px) and (max-width: 768px) {
  .DataStorageMainBody {
    /* height: 100vh; */
    background: #ffffff;
  }
}

.DataStorageBody {
  /* display: flex; */
  padding-top: 5rem;
  padding-bottom: 5rem;
}

@media screen and (max-width: 576px) {
  .DataStorageBody {
    padding-bottom: 5rem;
    padding-top: 0rem !important;
  }
}

@media screen and (min-width: 577px) and (max-width: 768px) {
  .DataStorageBody {
    padding-bottom: 5rem;
  }
}

@media screen and (min-width: 769px) and (max-width: 922px) {
  .DataStorageBody {
    padding-top: 0rem !important;
  }
}
@media screen and (min-width: 923px) and (max-width: 1200px) {
  .DataStorageBody {
    padding-top: 0rem !important;
  }
}

.DataStorageText {
  width: 100%;
  padding-top: 4rem;
  align-items: center;
}

@media screen and (min-width: 923px) and (max-width: 1200px) {
  .DataStorageText {}
}

@media screen and (min-width: 769px) and (max-width: 922px) {
  .DataStorageText {}
}

@media screen and (max-width: 576px) {
  .DataStorageText {}
}

@media screen and (min-width: 577px) and (max-width: 768px) {
  .DataStorageText {}
}

.DataStorageText h4 {
  font-family: "Clash Display";
  font-style: normal;
  font-weight: 600;
  font-size: 48px;
  line-height: 48px;
  color: #081f14;
  margin-bottom: 1.1rem;
  text-align: center !important;
}

@media screen and (min-width: 923px) and (max-width: 1200px) {
  .DataStorageText h4 {
    font-family: "Clash Display";
    font-style: normal;
    font-weight: 600;
    font-size: 38px;
    line-height: 48px;
    color: #081f14;
    margin-bottom: 1.1rem;
  }
}

@media screen and (max-width: 576px) {
  .DataStorageText h4 {
    font-family: "Clash Display";
    font-style: normal;
    font-weight: 600;
    font-size: 20px !important;
    line-height: 25px !important;
    text-align: center;
    color: #081f14;
  }
}

@media screen and (min-width: 577px) and (max-width: 768px) {
  .DataStorageText h4 {
    font-family: "Clash Display";
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 16px;
    text-align: center;
    color: #081f14;
    padding-top: 1rem !important;
  }
}

@media screen and (min-width: 769px) and (max-width: 922px) {
  .DataStorageText h4 {
    font-family: "Clash Display";
    font-style: normal;
    font-weight: 600;
    font-size: 33px;
    line-height: 40px;
    /* text-align: center; */
    color: #081f14;
    padding-top: 0rem !important;
  }
}

.CarouselParent {
  margin-top: 5rem;
}

@media screen and (max-width: 576px) {
  .CarouselParent {
    margin-top: 3rem;
  }
}

.CardImage {
  padding: 0.5rem 0.5rem 0.5rem 0.5rem !important;
  /* min-height: 500px !important; */
}

@media screen and (max-width: 576px) {
  .CardImage {
    padding: 0.5rem 0.5rem 0.5rem 0.5rem !important;
  }
}

@media screen and (min-width: 577px) and (max-width: 768px) {
  .CardImage {
    padding: 0.5rem 0.5rem 0.5rem 0.5rem !important;
    min-height: 25vh !important;
  }
}

.CardImage img {
  /* height: 200px !important; */
  height: 130px !important;
  border-radius: 12px 12px 0px 0px;
}

@media screen and (max-width: 576px) {
  .CardImage img {
    height: 100px !important;
    border-radius: 12px 12px 0px 0px;
  }
}

.CardBody {
  padding-top: 1rem;
  min-height: 250px !important;
}

@media screen and (max-width: 576px) {
  .CardBody {
    padding-top: 1rem;
    min-height: 210px !important;
  }
}

@media screen and (min-width: 577px) and (max-width: 768px) {

  .CardBody {
    padding-top: 1rem;
    min-height: 200px !important;
  }

}
/* @media screen and (min-width: 923px) and (max-width: 1200px) {

  .CardBody {
    padding-top: 1rem;
    min-height: 200px !important;
  }

} */

.CardBody h4 {
  font-family: "Clash Display";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 120%;
  color: #060606;
  text-align: left;
}

@media screen and (max-width: 576px) {
  .CardBody h4 {
    /* Desktop/H6/H6 Mid */

    font-family: 'Clash Display';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 120%;
    color: #02040F;
    text-align: left;
  }
}

@media screen and (min-width: 577px) and (max-width: 768px) {
  .CardBody h4 {
    font-family: "Clash Display";
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 120%;
    color: #060606;
    text-align: left;
  }
}

.CardBody p {
  font-family: "Clash Display";
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  line-height: 140%;
  color: #797979;
  text-align: justify;
}
@media screen and (max-width: 576px) {
  .CardBody p {
    /* Desktop/H6/H6 Mid */

    font-family: 'Clash Display';
    font-style: normal;
    font-weight: 400;
    font-size: 11px;
    line-height: 120%;
    color: #02040F;
    text-align: left;
  }
}

@media screen and (min-width: 577px) and (max-width: 768px) {
  .CardBody p {
    font-family: "Clash Display";
    font-style: normal;
    font-weight: 500;
    font-size: 11px;
    line-height: 140%;
    color: #797979;
    text-align: justify;
  }
}

.button {
  width: 100% !important;
  background: #1551B1 !important;
  border-radius: 4px !important;
}